import React, { VFC } from 'react'
import { Box } from '@mui/material'
import {
    TextField,
    TextInput,
    NumberInput,
    DateField,
    DateTimeInput,
    SelectInput,
    SimpleForm,
    useEditController,
    Edit,
    number,
    Labeled,
} from 'react-admin'
import { PROGRAM_CHOICES } from 'config'
import { getReservationStatusChoices } from '../../utils'

interface ReservationPageFieldProps {
    record?: any
    source: string
}

const ReservationPageField: VFC<ReservationPageFieldProps> = ({ record = {}, source }) => {
    return (
        <>
            {record[source] && (
                <a
                    href={`https://client.themonet.net/${record[source]}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    예약 확인 페이지
                </a>
            )}
        </>
    )
}
const ReservationEdit: VFC<any> = (props) => {
    const { onCancel, ...others } = props
    const validatePhone = [number('숫자만 입력해주세요.')]

    const controllerProps = useEditController({ ...others, mutationMode: 'pessimistic' })
    const { record } = controllerProps

    if (!record) {
        return null
    }

    return (
        <Edit resource="reservations">
            <SimpleForm record={record}>
                <Labeled label="ID">
                    <TextField source="id" />
                </Labeled>
                <ReservationPageField record={record} source="uid" />
                <br />

                <Box sx={{ display: 'flex', width: '100%' }}>
                    <Box sx={{ flex: 1 }}>
                        <TextInput label="이름" source="name" helperText="*필수" />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <TextInput
                            label="전화번호"
                            source="phone"
                            validate={validatePhone}
                            helperText="*필수"
                        />
                    </Box>
                    <Box sx={{ flex: 1 }} />
                </Box>
                <br />

                <Box sx={{ display: 'flex', width: '100%' }}>
                    <Box sx={{ flex: 1 }}>
                        <SelectInput
                            label="예약상태"
                            source="status"
                            choices={getReservationStatusChoices()}
                            fullWidth
                            helperText="*필수"
                            sx={{ width: '80%' }}
                        />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <DateTimeInput
                            label="예약날짜"
                            source="date"
                            fullWidth
                            helperText="*필수"
                            sx={{ width: '80%' }}
                        />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <SelectInput
                            label="프로그램"
                            source="program"
                            fullWidth
                            choices={PROGRAM_CHOICES}
                            helperText="*필수"
                            sx={{ width: '80%' }}
                        />
                    </Box>
                </Box>
                <br />

                <Box sx={{ display: 'flex', width: '100%' }}>
                    <Box sx={{ flex: 1 }}>
                        <NumberInput label="성인체험" source="adult" />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <NumberInput label="아이체험" source="children" />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <NumberInput label="입장만" source="guest" />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <NumberInput label="차량" source="car" />
                    </Box>
                </Box>
                <br />

                <Box sx={{ display: 'flex', width: '100%' }}>
                    <Box sx={{ flex: 1 }}>
                        <NumberInput
                            label="가격"
                            source="price"
                            fullWidth
                            helperText="*필수"
                            sx={{ width: '80%' }}
                        />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <TextInput label="입금계좌" source="bankAccount" fullWidth sx={{ width: '80%' }} />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <TextInput
                            label="환불계좌"
                            source="refundBankAccount"
                            fullWidth
                            sx={{ width: '80%' }}
                        />
                    </Box>
                </Box>
                <br />

                <Box sx={{ display: 'flex', width: '100%' }}>
                    <Box sx={{ flex: 1 }}>
                        <Labeled label="신청시간">
                            <DateField source="createdAt" showTime />
                        </Labeled>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Labeled label="변경시간">
                            <DateField source="updatedAt" showTime />
                        </Labeled>
                    </Box>
                </Box>
                <br />

                <TextInput label="메모" source="memo" variant="standard" multiline fullWidth />
            </SimpleForm>
        </Edit>
    )
}

export default ReservationEdit
