import gql from 'graphql-tag'
import { AuthProvider } from 'react-admin'

import { apolloClient } from './apolloClient'

const LOGIN = gql`
    query Login($password: String!) {
        login(password: $password)
    }
`

const authProvider: AuthProvider = {
    login: async ({ password }) => {
        console.log(password)
        const response = await apolloClient.query({ query: LOGIN, variables: { password } })

        if (!response?.data?.login) {
            return Promise.reject()
        }
        localStorage.setItem('themonet', 'true')
        return Promise.resolve()
    },
    logout: () => {
        localStorage.removeItem('themonet')
        return Promise.resolve()
    },
    checkAuth: () => {
        return localStorage.getItem('themonet') === 'true' ? Promise.resolve() : Promise.reject()
    },
    checkError: (error) => {
        const status = error.status
        if (status === 401 || status === 403) {
            localStorage.removeItem('themonet')
            return Promise.reject()
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve()
    },
    getIdentity: () => {
        return Promise.resolve({
            id: 'admin',
            fullName: '관리자',
        })
    },
    getPermissions: () => {
        return Promise.resolve('')
    },
    getRoles: () => {
        return Promise.reject('Not implemented')
    },
}

export default authProvider
