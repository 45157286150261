import React, { VFC } from 'react'
import {
    Edit,
    TextInput,
    DateInput,
    DateField,
    SimpleForm,
    Toolbar,
    SaveButton,
    useEditController,
} from 'react-admin'
import { Typography } from '@mui/material'

const SettingEditToolbar: VFC = () => (
    <Toolbar>
        <SaveButton />
    </Toolbar>
)

const SettingEdit: VFC<any> = (props) => {
    const { onCancel, ...others } = props
    const controllerProps = useEditController({
        ...others,
        resource: 'settings',
        mutationMode: 'pessimistic',
    })
    const { record } = controllerProps

    if (!record) {
        return null
    }

    return (
        <Edit>
            <SimpleForm toolbar={<SettingEditToolbar />}>
                <Typography variant="h5" gutterBottom>
                    설정
                </Typography>
                <TextInput label="관리자페이지 접속 암호" source="password" />
                <DateInput label="예약 마감 날짜" source="maxDate" />
                <DateField label="수정 시각" source="updatedAt" showTime />
            </SimpleForm>
        </Edit>
    )
}

export default SettingEdit
