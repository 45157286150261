import { ReservationStatus } from 'generated/graphql'
import { isEqual } from 'lodash'

export function stripTypename(value: any): any {
    if (value instanceof Date) {
        return new Date(value)
    }

    if (!value || typeof value !== 'object') {
        return value
    }

    if (Array.isArray(value)) {
        return value.map(stripTypename)
    }

    const result: any = {}
    Object.keys(value).forEach((key) => {
        if (key === '__typename') {
            return
        }
        result[key] = stripTypename(value[key])
    })

    return result
}

export function walk(value: any, callback: any, path: any = []) {
    if (!value || typeof value !== 'object' || Array.isArray(value)) {
        return callback(value, path)
    }

    Object.keys(value).forEach((key) => {
        walk(value[key], callback, [...path, key])
    })
}

function capitalize(token: string, acronyms: string[]) {
    if (token.length === 0) {
        return token
    }

    if (acronyms.indexOf(token) >= 0) {
        return token.toUpperCase()
    }

    return token[0].toUpperCase() + token.slice(1)
}

function reducePath(path: string[], acronyms: string[]) {
    return path.reduce((prev, token) => {
        return prev + capitalize(token, acronyms)
    })
}

export function extractUpdate(prev: any, cur: any, acronyms: string[]) {
    const result: any = {}

    const keys = Object.keys(cur)
    for (let i = 0; i < keys.length; ++i) {
        const key = keys[i]
        if (!isEqual(prev[key], cur[key])) {
            const value = stripTypename(cur[key])
            if (typeof value === 'object' && !Array.isArray(value)) {
                walk(
                    value,
                    (childValue: any, path: string[]) => {
                        result[reducePath(path, acronyms)] = childValue
                    },
                    [key],
                )
            } else {
                result[key] = value
            }
        }
    }

    return result
}

export function diff(prev: any, cur: any) {
    const result: any = {}
    const keys = Object.keys(cur)
    for (let i = 0; i < keys.length; ++i) {
        const key = keys[i]

        if (isEqual(prev[key], cur[key])) {
            continue
        }

        result[key] = cur[key]
    }

    return result
}

export function identity<T>(value: T): T {
    return value
}

export const urlRegex =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})|[a-zA-Z0-9]+\.[^\s]{2,}/

interface StatusChoice {
    id: string
    name: string
}

export const getReservationStatusChoices = () => {
    const choices: StatusChoice[] = [
        {
            id: ReservationStatus.WAITING,
            name: statusToText(ReservationStatus.WAITING),
        },
        {
            id: ReservationStatus.CONFIRM,
            name: statusToText(ReservationStatus.CONFIRM),
        },
        {
            id: ReservationStatus.CANCEL,
            name: statusToText(ReservationStatus.CANCEL),
        },
        {
            id: ReservationStatus.DONE,
            name: statusToText(ReservationStatus.DONE),
        },
    ]

    return choices
}

export const statusToText = (status: ReservationStatus) => {
    switch (status) {
        case ReservationStatus.WAITING: {
            return '대기'
        }
        case ReservationStatus.CONFIRM: {
            return '확인'
        }
        case ReservationStatus.CANCEL: {
            return '취소'
        }
        case ReservationStatus.DONE: {
            return '완료'
        }
        default: {
            return '확인되지 않음'
        }
    }
}
