import React from 'react'
import { TextInput, Form, required, useLogin, useNotify, useSafeSetState } from 'react-admin'
import { styled } from '@mui/material/styles'
import { Button, CardContent, CircularProgress } from '@mui/material'

export const LoginForm = (props: LoginFormProps) => {
    const { redirectTo, className } = props
    const [loading, setLoading] = useSafeSetState(false)
    const login = useLogin()
    const notify = useNotify()

    const submit = (values) => {
        setLoading(true)
        login(values, redirectTo)
            .then(() => {
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                        ? '비밀번호를 확인해주세요.'
                        : error.message,
                    {
                        type: 'warning',
                        messageArgs: {
                            _:
                                typeof error === 'string'
                                    ? error
                                    : error && error.message
                                    ? error.message
                                    : undefined,
                        },
                    },
                )
            })
    }

    return (
        <Form
            onSubmit={submit}
            mode="onChange"
            render={({ handleSubmit }) => (
                <Root onSubmit={handleSubmit} noValidate className={className}>
                    <CardContent className={LoginFormClasses.content}>
                        <TextInput
                            source="password"
                            label="관리자 비밀번호"
                            type="password"
                            autoComplete="current-password"
                            validate={required()}
                            fullWidth
                        />

                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={loading}
                            fullWidth
                            className={LoginFormClasses.button}
                        >
                            {loading ? (
                                <CircularProgress className={LoginFormClasses.icon} size={19} thickness={3} />
                            ) : (
                                '로그인'
                            )}
                        </Button>
                    </CardContent>
                </Root>
            )}
        />
    )
}

const PREFIX = 'RaLoginForm'

export const LoginFormClasses = {
    content: `${PREFIX}-content`,
    button: `${PREFIX}-button`,
    icon: `${PREFIX}-icon`,
}

const Root = styled('form', {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    [`& .${LoginFormClasses.content}`]: {
        width: 300,
    },
    [`& .${LoginFormClasses.button}`]: {
        marginTop: theme.spacing(2),
    },
    [`& .${LoginFormClasses.icon}`]: {
        margin: theme.spacing(0.3),
    },
}))

export interface LoginFormProps {
    redirectTo?: string
    className?: string
}
