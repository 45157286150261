import React, { VFC } from 'react'
import { Card, CardContent, useTheme } from '@mui/material'

import {
    List,
    DateField,
    Datagrid,
    TextField,
    TextInput,
    Filter,
    DateInput,
    number,
    downloadCSV,
} from 'react-admin'
import jsonExport from 'jsonexport/dist'

import rowStyle from './rowStyle'
import ReservationPeriodFilter from './components/ReservationPeriodFilter'
import ReservationStatusFilter from './components/ReservationStatusFilter'
import { statusToText } from '../../utils'

const ReservationFilter: VFC = (props) => {
    const validatePhone = [number('숫자만 입력해주세요.')]

    return (
        <Filter {...props}>
            <DateInput label="시작날짜" source="period.start" />
            <DateInput label="종료날짜" source="period.end" />
            <TextInput label="이름" source="name" alwaysOn resettable />
            <TextInput label="전화번호" source="phone" validate={validatePhone} alwaysOn resettable />
        </Filter>
    )
}

const ReservationFilterSidebar: VFC = () => (
    <Card
        sx={{
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginRight: '1em',
            display: ['none', 'block'],
        }}
    >
        <CardContent>
            <ReservationPeriodFilter />
            <ReservationStatusFilter />
        </CardContent>
    </Card>
)

const reservationExporter = (reservations) => {
    const reservationsForExport = reservations.map((reservation) => {
        const {
            id,
            name,
            phone,
            date,
            program,
            price,
            adult,
            children,
            guest,
            car,
            memo,
            createdAt,
            updatedAt,
        } = reservation
        const status = statusToText(reservation.status)

        return {
            id,
            name: name || '',
            phone: phone
                ? phone.substring(0, 3) + '-' + phone.substring(3, 7) + '-' + phone.substring(7, 11)
                : '',
            date: date ? new Date(date).toLocaleString().split(' 오')[0] : '',
            time: date
                ? new Date(date).getHours() +
                  ':' +
                  (new Date(date).getMinutes() < 10
                      ? '0' + new Date(date).getMinutes()
                      : new Date(date).getMinutes())
                : '',
            program,
            status,
            price: price || '',
            adult: adult || '',
            children: children || '',
            guest: guest || '',
            car: car || '',
            memo: memo || '',
            createdAt: new Date(createdAt).toLocaleString() || '',
            updatedAt: new Date(updatedAt).toLocaleString() || '',
        }
    })
    const BOM = '\uFEFF'
    jsonExport(
        reservationsForExport,
        {
            headers: [
                'id',
                'name',
                'phone',
                'date',
                'time',
                'program',
                'status',
                'price',
                'adult',
                'children',
                'guest',
                'car',
                'memo',
                'createdAt',
                'updatedAt',
            ],
            rename: [
                '번호',
                '이름',
                '연락처',
                '날짜',
                '시간',
                '프로그램',
                '예약상태',
                '가격',
                '성인체험',
                '아동체험',
                '입장만',
                '차량',
                '메모',
                '생성시간',
                '수정시간',
            ],
        },
        (err, csv) => {
            downloadCSV(`${BOM}${csv}`, '예약리스트') // download as 'posts.csv` file
        },
    )
}

interface ReservationListProps {
    selectedIds?: any
}

const ReservationList: VFC<ReservationListProps> = (props) => {
    const { selectedIds, ...others } = props
    const theme = useTheme()

    return (
        <List
            resource="reservations"
            filters={<ReservationFilter />}
            filterDefaultValues={{ date: null }}
            aside={<ReservationFilterSidebar />}
            exporter={reservationExporter}
        >
            <Datagrid {...others} rowClick="edit" rowStyle={rowStyle(selectedIds, theme)}>
                <TextField label="이름" source="name" sortable={false} />
                <TextField label="전화번호" source="phone" sortable={false} />
                <DateField label="예약날짜" source="date" showTime />
                <DateField label="신청날짜" source="createdAt" showTime />
                <TextField label="프로그램" source="program" sortable={false} />
                <TextField label="가격" source="price" sortable={false} />
                <TextField label="성인체험" source="adult" sortable={false} />
                <TextField label="아이체험" source="children" sortable={false} />
                <TextField label="입장만" source="guest" sortable={false} />
                <TextField label="차량" source="car" sortable={false} />
            </Datagrid>
        </List>
    )
}

export default ReservationList
