import React, { VFC, useState, useEffect, StrictMode } from 'react'
import { Admin, DataProvider, Resource } from 'react-admin'
import { BrowserRouter } from 'react-router-dom'

import { apolloClient, graphqlClient } from './apolloClient'
import { createDataProvider } from './dataProvider'
import authProvider from './authProvider'
import querieBuilders from './queries'
import * as resources from './resources'

import { Layout } from './components/layout'
import { Login } from './components/LoginPage'
import Dashboard from './components/Dashboard'
import { ApolloProvider } from '@apollo/client'

const App: VFC = () => {
    const [dataProvider, setDataProvider] = useState<DataProvider<string> | null>(null)

    useEffect(() => {
        setDataProvider(createDataProvider(graphqlClient, querieBuilders))
    }, [])

    if (!dataProvider) {
        return <div>Loading </div>
    }

    return (
        <StrictMode>
            <ApolloProvider client={apolloClient}>
                <BrowserRouter>
                    <Admin
                        layout={Layout}
                        dashboard={Dashboard}
                        dataProvider={dataProvider}
                        loginPage={<Login backgroundImage="/themonet.jpg" />}
                        authProvider={authProvider}
                    >
                        <Resource
                            name="reservations"
                            options={{ label: '예약 현황' }}
                            list={resources.ReservationList}
                            show={resources.ReservationShow}
                            create={resources.ReservationCreate}
                            edit={resources.ReservationEdit}
                        />
                        <Resource name="settings" options={{ label: '설정' }} edit={resources.SettingEdit} />
                    </Admin>
                </BrowserRouter>
            </ApolloProvider>
        </StrictMode>
    )
}

export default App
