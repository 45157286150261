import React, { VFC } from 'react'
import { TextField, DateField, Show, SimpleShowLayout } from 'react-admin'

const CoverLetterShow: VFC = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="phone" />
                <br />

                <TextField source="program" />
                <TextField source="price" />
                <DateField source="date" />
                <br />

                <TextField source="status" />
                <TextField source="bankAccount" />
                <TextField source="refundBankAccount" />
                <br />

                <TextField source="adult" />
                <TextField source="children" />
                <TextField source="car" />
                <br />

                <DateField source="createdAt" showTime />
                <DateField source="updatedAt" showTime />
                <br />
            </SimpleShowLayout>
        </Show>
    )
}

export default CoverLetterShow
